import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h3 futura bold color="#EF1412" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            {/* <CFLink href="https://www.facebook.com/SushiKKamizato/">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Sushi K Japanese Restaurant Facebook"
              />
            </CFLink> */}
            {/* <CFLink href="https://www.yelp.ca/biz/sushi-story-victoria">
              <CFImage
                w="45px"
                pr="10px"
                src={yelp}
                alt="Sushi K Japanese Restaurant Yelp"
              />
            </CFLink> */}
            {/* <CFLink href="https://www.zomato.com/victoria-bc/sushi-story-victoria-city-of-victoria">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="Sushi K Japanese Restaurant Zomato"
              />
            </CFLink> */}
            <CFLink href="https://www.instagram.com/sushik5959/?hl=en">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Sushi K Japanese Restaurant Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#EF1412" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            {/* <CFLink href="https://www.facebook.com/SushiKKamizato/">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Sushi K Japanese Restaurant Facebook"
                hover
              />
            </CFLink> */}
            {/* <CFLink href="https://www.yelp.ca/biz/sushi-story-victoria">
              <CFImage
                w="45px"
                pr="10px"
                src={yelp}
                alt="Sushi K Japanese Restaurant Yelp"
                hover
              />
            </CFLink> */}
            {/* <CFLink href="https://www.zomato.com/victoria-bc/sushi-story-victoria-city-of-victoria">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="Sushi K Japanese Restaurant Zomato"
                hover
              />
            </CFLink> */}
            <CFLink href="https://www.instagram.com/sushik5959/?hl=en">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Sushi K Japanese Restaurant Instagram"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
