import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, aboutParallax, mobileAbout, mobileAboutParallax } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <Parallax
          bgImage={mobileAboutParallax}
          bgImageAlt="Entree Dish"
          strength={200}
        >
          <div style={{ height: '400px' }} />
        </Parallax>
        <CFView column center>
          <CFImage
            p="30px 0"
            src={mobileAbout}
            w="100%"
            alt="Sushi K Japanese Restaurant About"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView>
          <CFView
            column
            justifyEnd
            alignCenter
            h="500px"
            w="100%"
            maxWidth="1400px"
            image={`url(${aboutParallax}) center / cover no-repeat`}
            style={{ backgroundAttachment: 'fixed' }}
          ></CFView>
          <CFImage src={about} w="100%" alt="Promotions" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
