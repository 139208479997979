import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import OrderPickupButton from './OrderPickupButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="95VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          center
        >
          <CFView textCenter column center w="90%">
            <CFImage
              w="100%"
              src={mobileHeroText}
              mt="80px"
              alt="Sushi K Japanese Restaurant Logo Title"
            />
            <CFView mt="55px" relative bottom="190px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) top/ cover no-repeat`}
          zIndex={90}
          column
          center
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <CFView column center w="100%" maxWidth="1400px">
            <CFView
              column
              center
              bg="rgba(0, 0, 0, 0.6)"
              h="65vh"
              maxHeight="400px"
              minHeight="350px"
              p="70px 110px 20px"
            >
              <CFImage
                h="230px"
                mt="-65px"
                src={heroText}
                alt="Sushi K Japanese Restaurant Logo Title"
              />
              <CFView mt="20px" pulsate>
                <OrderPickupButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
